import PageWrapper from './PageWrapper';
import Technology from '../components/Technology';
import {
  PythonLogo,
  ReactLogo,
  JavaLogo,
  TypescriptLogo,
  LaravelLogo,
  MysqlLogo,
  CSharpLogo,
  SequelizeLogo,
  NestJSLogo,
  NextJSLogo,
} from '../components/Icons';
import { Helmet } from 'react-helmet';

const Stack = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>$tack</title>
      </Helmet>
      <h1>My go-to stack</h1>
      <p>
        I am mainly a backend engineer, I hate front-end with a passion, however I am capable of working with stateful frameworks such as React to bind backends to their respective pages.
      </p>

      <Technology
        color="white"
        icon={<NextJSLogo />}
        name="NextJS"
        type="Frontend framework"
        useCase={'Dynamic SSG/SSR frontends for web apps'}
      />

      <Technology
        color="#181a1c"
        icon={<NestJSLogo />}
        name="NestJS"
        type="Frontend framework"
        useCase={'Clean architecture, scalable and efficient code'}
      />

      <Technology
        color="#232340"
        icon={<ReactLogo />}
        name="React"
        type="Frontend framework"
        useCase={'Constructing stateful and durable frontends for large and interactive web apps'}
      />

      <Technology
        color="#007acc"
        icon={<TypescriptLogo />}
        name="TypeScript"
        type="JS Framework"
        useCase={'Types for JS - projects scalability'}
      />

      <Technology
        color="#f2f2f2"
        icon={<JavaLogo />}
        name="Java"
        type="Object Oriented Backend"
        useCase={
          'Building efficient, optimized and scalable large infrastructures'
        }
      />

      <Technology
        color="#1e415e"
        icon={<PythonLogo />}
        name="Python"
        type="Scripting"
        useCase={
          'Writting efficient Proof Of Concepts - Quick parsing high volumes of data'
        }
      />

      <Technology
        color="#fcfbfc"
        icon={<LaravelLogo />}
        name="Laravel"
        type="Backend Framework"
        useCase={
          'Building large & Object Oriented RESTFUL applications'
        }
      />

      <Technology
        color="#ff8a26"
        icon={<MysqlLogo />}
        name="MySQL"
        type="Database"
        useCase={
          'Create powerful, meticulous and scalable databases'
        }
      />

      <Technology
        color="#6e4574"
        icon={<CSharpLogo />}
        name="C#"
        type="Object Oriented Backend"
        useCase={
          'Building efficient, optimized and scalable large infrastructures'
        }
      />

      <Technology
        color="#232325"
        icon={<SequelizeLogo />}
        name="Sequelize"
        type="Database ORM"
        useCase={
          'Quick to setup, lightweight & powerful database interpreter'
        }
      />

    </PageWrapper>
  );
};

export default Stack;
