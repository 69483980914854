import PageWrapper from './PageWrapper';
import Company from '../components/Company';
import styled from 'styled-components';
import DeadSuiteAppIcon from '../assets/images/ds-app-icon.png';
import SNKRepAppIcon from '../assets/images/snkrep-app-icon.png';
import TidalAppIcon from '../assets/images/tidal-app-icon.png';
import TrickleAppIcon from '../assets/images/trickle-app-icon.png';
import CometAppIcon from '../assets/images/comet-app-icon.png';
import NotifyAppIcon from '../assets/images/notify-app-icon.png';
import TitaniumAppIcon from '../assets/images/titanium-app-icon.png';
import SavageNationAppIcon from '../assets/images/savage-nation-app-icon.png'
import Repo from '../components/Repo';
import { Helmet } from 'react-helmet';

const Projects = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      <h1>My projects</h1>
      <h3>Companies</h3>
      <CoWrapper>
        <Company
          url="https://savagenationnft.com/"
          name="Savage Nation"
          iconReference={SavageNationAppIcon}
          tagline="NFT Project"
          position={'Backend Engineer'}
          about={'Building raffle / minting / reveal / api / webhooks / in-house dashboard / gallery aslong as managing and handling deployments'}
        />
        <Company
          url="https://web.archive.org/web/20220701005454/https://cometproxies.com/"
          name="Comet Proxies"
          iconReference={CometAppIcon}
          tagline="Proxy Provider"
          position={'Backend Engineer'}
          about={'Maintaining dashboard, implement sale of different types of Proxy, in-house real-time proxied data analyzing monitor'}
          status={'Ar©hived'}
        />
        <Company
          url="https://notify.org/"
          name="Notify"
          iconReference={NotifyAppIcon}
          tagline="Paid Reselling Group"
          position={'Backend Engineer'}
          about={'Maintenance, implementation and migrations of existings tools into a single monolith solution.'}
          status={'Ar©hived'}
        />
        <Company
          url="https://discord.gg/e9GyMM7wQJ"
          name="Titanium"
          iconReference={TitaniumAppIcon}
          tagline="Rust Competitive Server"
          position={'Founder & Developer'}
          about={'Innovative highly competitive Rust™ server. Lead of project, web/game development and marketing.'}
        />
        <Company
          url="https://web.archive.org/web/20220103204636/https://deadsuite.xyz/"
          name="DeadSuite"
          iconReference={DeadSuiteAppIcon}
          tagline="Limited Purchases Automation"
          position={'Founder & Developer'}
          about={'Launched in 2019 to allow consumers to buy Sneaker Bots at a retail price blazing fast.'}
          status={'A€quired'}
        />
        <Company
          url="https://snkrep.com/"
          name="SNKRep"
          iconReference={SNKRepAppIcon}
          tagline="Fraud Prevention"
          position={'Founder & Developer'}
          about={'Founded in February 2021 to provide a public tool to ensure safe transactions.'}
          status={'A€quired'}
        />
        <Company
          url="https://tidalmarket.com/"
          name="Tidal"
          iconReference={TidalAppIcon}
          tagline="Software Marketplace"
          position={'Chief Technology Officer (CTO)'}
          about={'Lead designs, implementations and deployments of all Market/Rentals services.'}
          status={'Ar©hived'}
        />
        <Company
          url="https://trickle.bot/"
          name="Trickle"
          iconReference={TrickleAppIcon}
          tagline="Limited Purchases Automation"
          position={'Backend Developer'}
          about={'Entire dashboard\'s back-end & front-end binding / in-house renewal system.'}
          status={'Ar©hived'}
        />
      </CoWrapper>

      <h3>More work / I own...</h3>
      <Repo
        name={'deadsolutions'}
        url={'https://twitter.com/DeadSolutions'}
        primaryLanguage={'Multiple'}
        description="Commissions for cutting-edge solutions curated for groups & bots."
      />
      <Repo
        name={'webmafia'}
        url={'https://twitter.com/DevsMafia'}
        primaryLanguage={'Multiple'}
        description="Private collective of Developers & Engineers."
      />
      <Repo
        name={'botraffles'}
        url={'https://botraffles.com/'}
        primaryLanguage={'NodeJS'}
        description="Automated raffles for sneaker botting solutions."
      />
    </PageWrapper>
  );
};

const CoWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 2rem 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export default Projects;
