import PageWrapper from './PageWrapper';
import { useMemo } from 'react';

const START_DEV = new Date('2014-07-07T00:00:00Z');
const YEAR_MILLIS = 31556952000;

const Home = () => {

  const dev_years = useMemo(() => Math.floor((Date.now() - START_DEV.getTime()) / YEAR_MILLIS), []);

  return (
    <PageWrapper forceReadableWidth>
      <h1>Bio</h1>
      <p>
        Delu. young/passionated software engineer / visionary with {dev_years} years experience in the IT domain
      </p>
      <p>
        I envision, conceptualize, build and publish high standards products/projects. I make any ideas become a reality. Currently, I am the Lead Engineer
        at{' '}
        <a href="https://www.agency-aurora.com/" target="norel noopen">
          Aurora
        </a>
        .
      </p>

      <p>
        Site is severely outdated, if you wish to learn more about me, don't hesitate to message me !
      </p>
    </PageWrapper>
  );
};

export default Home;
